<template>
  <span class="tag-title">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'tag-title',
  props: {
    text: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {}
}
</script>

<style scoped lang="stylus">
@import '~styles/variables.styl'

.tag-title
  padding 2px 10px
  margin 0 2px 0 0
  text-align center
  border-radius 2px
  color $invalid-color
  background $invalid-bg
</style>
