var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-addition__list" },
    [
      _vm.isHis
        ? _c(
            "ul",
            _vm._l(_vm.sortedList, function (item) {
              return _c(
                "li",
                {
                  key: item.law_id,
                  staticClass: "law-his",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toLaw(item, item.law_title)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "date" }, [
                    _vm._v(_vm._s(item.pub_date || "")),
                  ]),
                  _vm._m(0, true),
                  !_vm.isValid(item.timeliness)
                    ? _c("tag-title", { attrs: { text: item.timeliness } })
                    : _vm._e(),
                  _c("div", { staticClass: "main-title flex-1 ellipsis" }, [
                    _vm._v("《" + _vm._s(item.law_title) + "》"),
                  ]),
                ],
                1
              )
            }),
            0
          )
        : _vm._l(_vm.list, function (addi, i) {
            return _c("ul", { key: addi.law_id + i, staticClass: "list" }, [
              _c(
                "li",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toLaw(addi, addi.title)
                    },
                  },
                },
                [
                  !_vm.isValid(addi.timeliness)
                    ? _c("tag-title", { attrs: { text: addi.timeliness } })
                    : _vm._e(),
                  _c("span", { staticClass: "main-title" }, [
                    _vm._v("《" + _vm._s(addi.law_title) + "》"),
                  ]),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v(_vm._s(addi.title)),
                  ]),
                ],
                1
              ),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "time-line" }, [
      _c("div", { staticClass: "line-y" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }