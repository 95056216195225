<template>
  <div class="law-addition__list">
    <ul v-if="isHis">
      <li
        v-for="item in sortedList"
        :key="item.law_id"
        class="law-his"
        @click.stop="toLaw(item, item.law_title)"
      >
        <div class="date">{{ item.pub_date || '' }}</div>
        <div class="time-line">
          <div class="line-y"></div>
        </div>
        <tag-title v-if="!isValid(item.timeliness)" :text="item.timeliness" />
        <div class="main-title flex-1 ellipsis">《{{ item.law_title }}》</div>
      </li>
    </ul>
    <!-- <ul v-else v-for="addi in list" :key="addi.law_id" class="list">
      <template v-if="addi.child && addi.child.length > 0">
        <li
          v-for="sub in addi.child"
          :key="sub.title"
          class="item"
          @touchend.stop.prevent="toLaw(addi, sub.title)"
        >
          <tag-title v-if="!isValid(addi.timeliness)" :text="addi.timeliness" />
          <span class="main-title">《{{ addi.law_title }}》</span>
          <span class="sub-title">{{ sub.title }}</span>
        </li>
        <div class="quote"></div>
      </template>
    </ul> -->
    <ul v-for="(addi, i) in list" v-else :key="addi.law_id + i" class="list">
      <li class="item" @click.stop="toLaw(addi, addi.title)">
        <tag-title v-if="!isValid(addi.timeliness)" :text="addi.timeliness" />
        <span class="main-title">《{{ addi.law_title }}》</span>
        <span class="sub-title">{{ addi.title }}</span>
      </li>
      <!-- 历史沿革 -->
    </ul>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { deepCopy } from '@/utils/assist'
import TagTitle from './tag-title.vue'

export default {
  name: 'law-addition',
  props: {
    name: {
      type: String,
      default: () => '关联法条',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TagTitle,
  },
  data() {
    return {}
  },
  computed: {
    isHis({ name }) {
      return name === '历史沿革'
    },
    sortedList({ isHis }) {
      if (!this) return []
      const l = deepCopy(this.list)
      return l.sort(this.sortByTime('exc_date'))
    },
  },
  methods: {
    isValid(timeliness) {
      return timeliness === '现行有效'
    },
    toLaw(addi, toCatalog = '') {
      this.$emit('toLaw', { ...addi, toCatalog })
    },
    sortByTime(keyField) {
      return (cur, next) => {
        const a = dayjs(cur[keyField]).unix()
        const b = dayjs(next[keyField]).unix()
        return b - a
      }
    },
  },
  mounted() {},
  created() {},
}
</script>

<style scoped lang="stylus">
.law-addition__list
  position relative
  padding 2px
  & .list
    margin 0 0 0 20px
    & .item
      padding 2px 0
      list-style disc
      cursor pointer
      &:hover
        background #eee
  & .law-his
    height 28px
    display flex
    align-items center
    position relative
    cursor pointer
    & .date
      width 65px
    & .time-line
      background-color #e4e7ed
      border-radius 50%
      width 16px
      height 16px
      display inline-flex
      justify-content center
      align-items center
      margin 0 5px
      position relative
      & .line-y
        border-left 2px solid #e4e7ed
        position absolute
        top 10px
        left calc(50% - 1px)
        height 32px
    &:nth-last-child(1)
      & .time-line
        color red
        & .line-y
          display none
</style>
